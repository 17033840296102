import React, { useState, useContext } from 'react'
import Anuncio from '../components/anuncios/Anuncio'
import { useSelector } from 'react-redux'
import LoadIcon from '../images/loading.gif'
import ListAnuncio from '../components/anuncios/ListAnuncio'
import Posttotal from '../components/anuncios/Posttotal'
import PostUsers from '../components/anuncios/PostUsers'

import ErrorFont from '../components/ErrorFont'

import { GlobalState } from '../GlobalState'
import StatusP from '../components/publicidad/StatusP'
import StatusCom from '../components/perfilcomer/StatusCom'

function Veranuncio() {

  //tipo de usuario
  const state = useContext(GlobalState)
  const [isAdmin] = state.userAPI.isAdmin
  //

  const { homeAnuncio } = useSelector(state => state)
  const [saveTab, setSaveTab] = useState(false)

  //////////////
  const [activeButton, setActiveButton] = useState(1);

  const handleButtonClick = (buttonNumber) => {
    setActiveButton(buttonNumber);
  };

  // Definir el estado de los botones
  const [botones, setBotones] = useState([
    { id: 1, activo: true },
    { id: 2, activo: false },
    { id: 3, activo: false }
  ]);
  // Función para activar un botón y desactivar los demás
  const handleClick = (indice) => {
    const nuevosBotones = [...botones];
    nuevosBotones.forEach((boton, i) => {
      if (i === indice) {
        boton.activo = true;
      } else {
        boton.activo = false;
      }
    });
    setBotones(nuevosBotones);
  }
  /*-----------*/

  return (
    <div className='anuncio row mx-0'>
      <StatusCom />
      {
        isAdmin
          ? <>
            {
              isAdmin
                ? <>
                  <div className='anuncio_statusP_veranuncio'>
                    <StatusP />
                  </div>
                  <Anuncio />
                </>
                : <ErrorFont />
            }
            {
              isAdmin
                ? <>
                  <div className="profile_tab" id="profiles_btn_perfil" >
                    <button onClick={() => { handleButtonClick(1); handleClick(0) }} id={botones[0].activo ? 'activebuttoH' : ''} >Usuarios</button>
                    <button onClick={() => { handleButtonClick(2); handleClick(1) }} id={botones[1].activo ? 'activebuttoH' : ''} >Anuncios</button>
                    <button onClick={() => { handleButtonClick(3); handleClick(2) }} id={botones[2].activo ? 'activebuttoH' : ''} >Posts</button>
                  </div>
                  <div>
                    {
                      homeAnuncio.loading
                        ? <img className="d-block mx-auto" src={LoadIcon} alt="loading" />
                        : <>
                          <div>
                            {activeButton === 1 &&
                              <>
                                <Posttotal />
                              </>}
                          </div>
                          <div>
                            {activeButton === 2 &&
                              <>
                                <ListAnuncio />
                              </>
                            }
                          </div>
                          <div>
                            {activeButton === 3 &&
                              <>
                                <PostUsers />
                              </>
                            }
                          </div>
                        </>
                    }
                  </div>
                </>
                : <ErrorFont />
            }
          </>
          : <ErrorFont />
      }

    </div>
  )
}

export default Veranuncio