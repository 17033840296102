import React, { useState, useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PostCard from '../PostCard'

import LoadIcon from '../../images/loading.gif'
import LoadMoreBtn from '../LoadMoreBtn'
import { getDataAPI } from '../../utils/fetchData'
import { POST_TYPES } from '../../redux/actions/postAction'

import { GlobalState } from '../../GlobalState'
import { Link } from 'react-router-dom'

import VideoAlter from '../homealterna/VideoAlter'
import AnuncioAlter from '../homealterna/AnuncioAlter'


const Posts = () => {
    //tipo de usuario
    const state = useContext(GlobalState)
    const [isUservip] = state.userAPI.isUservip
    //
    const { homePosts, auth, theme } = useSelector(state => state)
    const dispatch = useDispatch()

    const [load, setLoad] = useState(false)

    const handleLoadMore = async () => {
        setLoad(true)
        const res = await getDataAPI(`posts?limit=${homePosts.page * 9}`, auth.token)

        dispatch({
            type: POST_TYPES.GET_POSTS,
            payload: { ...res.data, page: homePosts.page + 1 }
        })

        setLoad(false)
    }

    return (
        <div className="posts">

            {homePosts.posts.map((post, index) => {
                if ((index + 1) % 6 === 0) {
                    return (
                        <React.Fragment key={post._id}>
                            <AnuncioAlter />
                            <PostCard post={post} theme={theme} />
                        </React.Fragment>
                    );
                } else {
                    return <PostCard key={post._id} post={post} theme={theme} />;
                }
            })}

            {
                load && <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
            }

            {
                homePosts.posts.length > 8
                    ? <>
                        {
                            isUservip
                                ? <>
                                    {
                                        !homePosts.loading &&
                                        <LoadMoreBtn result={homePosts.result} page={homePosts.page}
                                            load={load} handleLoadMore={handleLoadMore} />
                                    }
                                </>
                                : <>
                                    {
                                        homePosts.posts.length > 24
                                            ? <>
                                                <div className='panel_informe_suscripsion' style={{ filter: theme ? 'invert(1)' : 'invert(0)' }}>
                                                    <div><i class="fa-solid fa-lock"></i></div>
                                                    <h2>Desbloquea todas las publicaciones de Usuarios que sigues de una <span id='span_infor_panel'>Forma Facil</span> </h2>
                                                    <div><span>Suscribete para descubrir todas las publicaciones de los usuarios que sigues, rapido y sin tarjeta, solo
                                                        a traves de nuestras diferentes redes</span></div>
                                                    <Link to={'/precios'}>
                                                        <button>Suscribete Ahora</button>
                                                    </Link>
                                                </div>
                                            </>
                                            : <>
                                                {
                                                    !homePosts.loading &&
                                                    <LoadMoreBtn result={homePosts.result} page={homePosts.page}
                                                        load={load} handleLoadMore={handleLoadMore} />
                                                }
                                            </>
                                    }

                                </>
                        }

                    </>
                    : <></>
            }

        </div >
    )
}

export default Posts
