import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { login } from '../redux/actions/authAction'
import { useDispatch, useSelector } from 'react-redux'

//import MainPages from '../components/mainpages/Pages'


const Login = () => {

    const initialState = { email: '', password: '' }
    const [userData, setUserData] = useState(initialState)
    const { email, password } = userData

    const [typePass, setTypePass] = useState(false)

    const { auth } = useSelector(state => state)
    const dispath = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (auth.token) history.push("/")
    }, [auth.token, history])

    const handleChangeInput = e => {
        const { name, value } = e.target
        setUserData({ ...userData, [name]: value })
    }

    const handleSubmit = async e => {
        e.preventDefault()
        dispath(login(userData))
    }


    return (
        <div className="auth_page-login">
            <form onSubmit={handleSubmit} >
                <div className="titulo_login_">
                    <Link to="/" style={{ textDecoration: 'none' }} >
                        <h1 className="gradient-text" id='logo_css' 
                            onClick={() => window.scrollTo({ top: 0 })}>
                            Cariñosas Hub
                        </h1>
                    </Link>
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                    <input type="email" className="form-control" id="exampleInputEmail1" name="email" aria-describedby="emailHelp"
                        onChange={handleChangeInput} value={email} />
                    <small id="emailHelp" className="form-text text-muted">
                        Su correo se mantendra en estricto privado
                    </small >
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Password</label>

                    <div className="pass">
                        <input type={typePass ? "text" : "password"} className="form-control" id="exampleInputPassword1"
                            onChange={handleChangeInput} value={password.replace(/ /g, '')} name="password" />
                        <small onClick={() => setTypePass(!typePass)} >
                            {typePass ? 'Esconder' : 'Mostrar'}
                        </small>
                    </div>
                </div>
                <button type="submit" className="btn-dark-bt"
                    disabled={email && password ? false : true}>
                    Entrar
                </button>
                <p className="my-2">
                    no tienes una cuenta? <Link to="/register" style={{ color: "crimson" }} >Regístrate ahora</Link>
                </p>
                {/*<p className="my-2">
                    productos<Link to="/productos" style={{ color: "crimson" }} >productos</Link>
    </p>*/}
            </form>
        </div>
    )
}

export default Login