import React, { useContext, useState, useRef } from 'react'
import Loading from '../components/mainpages/utils/loading/Loading'
import { GlobalState } from '../GlobalState'
import LoadMoreAnu from '../components/mainpages/products/LoadMoreAnu'
import { Link } from 'react-router-dom'
import HeaderVer from '../components/compInicio/headerVertical'
import BarraDer from '../components/compInicio/barraDerech'
import Footer from '../components/compInicio/footer'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Slider from "react-slick";
import ChatButton from '../utils/chatButton'
import LoadMorePublic from '../components/mainpages/products/LoadMorePublic'
import CategoriaPublic from '../components/publicidad/CategoriaPublic'
import ScrollToTop from '../utils/ScrollToTop'

import { Helmet } from 'react-helmet-async';

/*------*/
function filterByCategory(categoryName, categoryFilters, item) {
    if (!categoryFilters.length) return true;
    return categoryFilters.some((filter) => item[categoryName] === filter);
}
/*--------*/

function Inicio() {
    const [loading, setLoading] = useState(false)
    const state = useContext(GlobalState)

    const directoryRef = useRef()

    /***- */
    const [anuncios] = state.publicidadAPI.publicidad
    //publicidad reciente
    const [anunciosrecientes] = state.publicidadAPI.publicidadrecien

    /*-------------------*/
    const [item, setItem] = useState(anuncios);

    const getItem = (cat) => {
        var upadatedValue = anuncios.filter((items) => {
            return items.provincia === cat;
        });
        setItem(upadatedValue);
    };
    /*--------------*/

    const appTypeFilters = [...new Set(item.map((d) => d.distrito))];
    const nacionalidadFilters = [...new Set(anuncios.map((d) => d.nacionalidad))];

    let [appTypes, setAppTypes] = useState([]);
    let [nacionalidads, setNacionalidads] = useState([]);


    let filteredDevelopers = anuncios.filter((dev) => {
        return (
            filterByCategory("distrito", appTypes, dev) &&
            filterByCategory("nacionalidad", nacionalidads, dev)
        )
    });

    /*-----------*/

    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        variableWidth: true,
        centerPadding: "60px",
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    }

    const settings3 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
    }

    ////modal
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    /////

    ////modal2
    const [show1, setShow1] = useState(false);

    const handleClose1 = () => setShow1(false);
    const handleShow1 = () => setShow1(true);

    const handleClick1 = () => {
        window.scrollTo({ top: 200, behavior: 'smooth' });
    };

    const handleButtonClick = () => {
        handleShow1();
        handleClick1();
    };

    if (loading) return <div><Loading /></div>

    const tituloDinamico = "Kinesiologas en Perú- CARIÑOSASHUB";

    return (
        <>
            <Helmet>

                <title>{tituloDinamico}</title>

                <meta name="description"
                    content="Kinesiologas en Peru: Lince, Miraflores, San Borja, San Isidro, Chicas delivery, Kines y putas economicas en Lima, Los Olivos."
                ></meta>

                <meta name="keywords"
                    content="kines, kinesiologas, putas, lima, escorts, kines peru, damas kinesiologas, kinesiologas extranjeras, putas en peru, provincias kinesiologas, sexo, placer, provincias, damas acompañantes, kinesiologas en peru."></meta>

                <link rel="canonical" href="https://www.cariñosashub.com/" />

            </Helmet>
            {show && (
                <div id="alerta-titulo-telegram">
                    <a href='https://t.me/carinosashub' >
                        <span>Unete a nuestro grupo de Telegram @carinosashub</span>
                    </a>
                    <button onClick={handleClose} type="button" class="outline-danger"><i class="fa-solid fa-xmark"></i></button>
                </div>
            )}

            {show1 && (
                <div className="status_modal_alert_sus" style={{ top: '0px' }}>
                    <div id="alerta-titulo">
                        <i class="fa-solid fa-user"></i>
                        <h4>
                            Para poder guardar ó darle like a un anuncio, ienes que unirte a nuestra red social "CariñosasHub", es gratis!!!
                        </h4>
                        <div>
                            <button onClick={handleClose1} type="button" class="outline-secondary">Cerrar</button>
                            <Link to={"/register"}>
                                <button type="button" class="outline-danger" >Suscribese</button>
                            </Link>
                        </div>
                    </div>
                </div>
            )}

            <div className='rj-container-sections'>
                <div className='rj-izquierda'>
                    <HeaderVer />
                </div>
                <div className='rj-central'>
                    <ScrollToTop />
                    <ChatButton />
                    <div className='container-pruebaPP'>
                        <div className='portada_red_social'>
                            <div className='uno_portada'>
                                <Slider {...settings3}>
                                    <div className='img_carousel_portal' id='img_carousel_portal'>
                                        <div className='portal_content_'>
                                            <h1>Cariñosas Hub</h1>
                                            <p>
                                                Unete a nuestra Red social CariñosasHub y sigue a tú cariñosa fovorita..
                                            </p>
                                        </div>
                                        <div className='img_segun_portal'>
                                            <img src="https://media.gq.com.mx/photos/5dc7210c6c7d2100088eb408/master/w_1600%2Cc_limit/GettyImages-544021513.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className='img_carousel_portal' id='img_carousel_portal'>
                                        <div className='portal_content_'>
                                            <h1>Cariñosas Hub</h1>
                                            <p>
                                                Podras ver anuncios, imagenes, publicaciones de tú cariñosa favorita..
                                            </p>
                                        </div>
                                        <div className='img_segun_portal'>
                                            <img src="https://images.ecestaticos.com/oTHEzjCpa6S6SJuG-WfgWndkyKE=/0x16:1693x970/1600x900/filters:fill(white):format(jpg)/f.elconfidencial.com%2Foriginal%2F415%2Fc7b%2Fbfa%2F415c7bbfa0b9d6322d8802920710a3ad.jpg" alt="" />
                                        </div>
                                    </div>
                                    <div className='img_carousel_portal' id='img_carousel_portal'>
                                        <div className='portal_content_'>
                                            <h1>Cariñosas Hub</h1>
                                            <p>
                                                Tendras un perfil para poder hacer publicaciones elevadas de tono,  reciviras notificaciones de tú cariñosa preferida..
                                            </p>
                                        </div>
                                        <div className='img_segun_portal'>
                                            <img src="https://www.65ymas.com/uploads/s1/81/82/16/bigstock-sexy-woman-maked-body-with-but-415843051_5_1242x621.jpeg" alt="" />
                                        </div>
                                    </div>
                                    <div className='img_carousel_portal' id='img_carousel_portal'>
                                        <div className='portal_content_'>
                                            <h1>Cariñosas Hub</h1>
                                            <p>
                                                En CariñosasHub podras encontarar avisos y publicaciones de kinesiologas, contenido y otros servicios...
                                            </p>
                                        </div>
                                        <div className='img_segun_portal'>
                                            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7vtvYU-zb3qAyCgV3iMf5pvh2Ga5ksvK8wna0p18fHyfnCKhAfcz9zDFjlCGcsAy4VoY&usqp=CAU" alt="" />
                                        </div>
                                    </div>
                                </Slider>
                            </div>
                            <div className='dos_portada'>
                                <div class="container_profile_">
                                    <div class="profile_avatar_">
                                        <img src="https://cinepornogratis.com/wp-content/uploads/2022/03/lorena-garcia-actriz-nopor-320x180.jpg"
                                            alt="" />
                                    </div>
                                    <div class="profile_avatar_">
                                        <img src="https://cdni.pornpics.com/460/7/632/54078320/54078320_048_acc2.jpg"
                                            alt="" />
                                    </div>
                                    <div class="profile_avatar_">
                                        <img src="https://www.elergonomista.com/wp-content/uploads/2019/05/Valentina-Nappi.jpg"
                                            alt="" />
                                    </div>
                                    <div class="profile_avatar_">
                                        <img src="https://xxxlesvianas.com/wp-content/uploads/2022/09/Angela-White.webp"
                                            alt="" />
                                    </div>
                                    <div class="profile_avatar_">
                                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2dq91z8t1aGGn4ofIaUWX0V-qUoq9rnmFu-lcR078p0xv1zq25BbHtfVni07RE3Y9xEI&usqp=CAU"
                                            alt="" />
                                    </div>
                                    <div class="profile_avatar_">
                                        <img src="https://pbs.twimg.com/media/EAkJEx8XkAIqzud.jpg"
                                            alt="" />
                                    </div>
                                    <div class="profile_avatar_">
                                        <img src="https://www.pornostars.xxx/wp-content/uploads/2018/08/aletta-ocean.jpg"
                                            alt="" />
                                    </div>
                                </div>
                                <div class="container_profile_content">
                                    <div className='cariñosas_title_red'>
                                        <h4>Tú</h4><h3> Cariñosas</h3>
                                    </div>
                                    <p>Sigue a tú cariñosa favorita, mira sus publicaciones mas Hot...</p>
                                    <Link to="/register">
                                        <span><i class="fa-sharp fa-solid fa-square-rss"></i>Unete</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <CategoriaPublic />
                        <div className='filtro_anuncio_'>
                            <div class="menu-c">
                                <label for="btn-menus" className="abrir-menu"><i class="fa-solid fa-filter"></i></label>
                            </div>
                            <input type="checkbox" id="btn-menus" />
                            <h2>Filtrar: </h2>
                            <div id="menus" classNames="menu-c">
                                <div className="Provincias_tags">
                                    <h3>Provincia</h3>
                                    <Slider {...settings}>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Amazonas")}
                                            >
                                                Amazonas
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Ancash")}
                                            >
                                                Ancash
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Apurímac")}
                                            >
                                                Apurímac
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Ayacucho")}
                                            >
                                                Ayacucho
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Cajamarca")}
                                            >
                                                Cajamarca
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Callao")}
                                            >
                                                Callao
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Cusco")}
                                            >
                                                Cusco
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Huancavelica")}
                                            >
                                                Huancavelica
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Huanuco")}
                                            >
                                                Huanuco
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Ica")}
                                            >
                                                Ica
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Junin")}
                                            >
                                                Junin
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("La Libertad")}
                                            >
                                                La Libertad
                                            </button>
                                        </div>
                                    </Slider>
                                    <Slider {...settings}>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Lambayeque")}
                                            >
                                                Lambayeque
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Lima")} style={{ color: 'red' }}
                                            >
                                                Lima
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Loreto")}
                                            >
                                                Loreto
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Madre De Dios")}
                                            >
                                                Madre De Dios
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Moquegua")}
                                            >
                                                Moquegua
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Pasco")}
                                            >
                                                Pasco
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Piura")}
                                            >
                                                Piura
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Puno")}
                                            >
                                                Puno
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("San Martin")}
                                            >
                                                San Martin
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Tacna")}
                                            >
                                                Tacna
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Tumbes")}
                                            >
                                                Tumbes
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Ucayali")}
                                            >
                                                Ucayali
                                            </button>
                                        </div>
                                        <div>
                                            <button className="outline_secondary" onClick={() => setItem(anuncios)}
                                                style={{ background: '#E74C3C', color: '#FDEDEC' }}>
                                                All
                                            </button>
                                        </div>
                                    </Slider>
                                    <div>
                                        <div>
                                            <button
                                                className="outline_secondary"
                                                onClick={() => getItem("Lima")}
                                            >
                                                Lima
                                            </button>
                                        </div>
                                        <div>
                                            <button className="outline_secondary" onClick={() => setItem(anuncios)}
                                                style={{ background: '#E74C3C', color: '#FDEDEC' }}>
                                                Todos
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="contenedor_tags">
                                    <h3>Distrito</h3>
                                    <ul className="boxs-container" >
                                        {appTypeFilters.map((type) => (
                                            <li className='content_dist'>
                                                <input
                                                    className="inp-cbx" id={type}
                                                    name="appType"
                                                    type="checkbox"
                                                    value={type}
                                                    onClick={(e) => {
                                                        if (e.target.checked) {
                                                            setAppTypes([...appTypes, type]);
                                                        } else {
                                                            setAppTypes(
                                                                appTypes.filter((distrito) => distrito !== type)
                                                            );
                                                        }
                                                    }}
                                                />
                                                <label for={type} href="#lancha" >{type}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="contenedor_tags">
                                    <h3>Nacionalidad</h3>
                                    <ul className="boxs-container2">
                                        {nacionalidadFilters.map((filter) => (
                                            <li className='content_dist'>
                                                <input
                                                    className="inp-cbx" id={filter}
                                                    name="nacionalidad"
                                                    type="checkbox"
                                                    value={filter}
                                                    onClick={(e) => {
                                                        if (e.target.checked) {
                                                            setNacionalidads([...nacionalidads, filter]);
                                                        } else {
                                                            setNacionalidads(
                                                                nacionalidads.filter((nacionalidad) => nacionalidad !== filter)
                                                            );
                                                        }
                                                    }}
                                                />
                                                <label for={filter}>{filter}</label>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <label for="btn-menus" id="cerrar-menu">X</label>
                            </div>
                        </div>
                        {anuncios.length === 0 && <Loading />}
                        <h2 ref={directoryRef} >Directorio De Chicas</h2>
                        <>
                            <div className='main-container'>
                                <div class="card-principal">
                                    {
                                        anunciosrecientes.map(anuncio => {
                                            return <div class="card-secun">
                                                <div className="img-card">
                                                    <Link id="btn_ver_list" to={`/kines/${anuncio._id}`} >
                                                        <img src={anuncio.images[0].url} alt="" />
                                                    </Link>
                                                    <span className='material-icons'
                                                        onClick={handleButtonClick}>favorite</span>
                                                </div>
                                                <div className='card-footer1'>
                                                    <div className='card-nombre'>
                                                        <div className='card-name-nombre'>
                                                            <i class="fa-solid fa-star"></i>
                                                            <h1 style={{ marginLeft: '2px' }}>{anuncio.nombre}</h1>
                                                        </div>
                                                        <span>{anuncio.edad} <small>años</small></span>
                                                    </div>
                                                    <div className='card-footer-nacio'>
                                                        <img src={anuncio.iconBanader} />
                                                        <span>{anuncio.nacionalidad}</span>
                                                    </div>
                                                    <div className='card-lugar'>
                                                        <div>
                                                            <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691439005/iconos/pointuno_hjti6w.png' />
                                                            <span>{anuncio.distrito}</span>
                                                        </div>
                                                        <small>{anuncio.provincia}</small>
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        </>
                        <div className='main-container'>
                            <div class="card-principal">
                                {
                                    filteredDevelopers.map(anuncio => {
                                        return <div class="card-secun">
                                            <div className="img-card">
                                                <Link id="btn_ver_list" to={`/kines/${anuncio._id}`} >
                                                    <img src={anuncio.images[0].url} alt="" />
                                                </Link>
                                                <span className='material-icons'
                                                    onClick={handleButtonClick}>favorite</span>
                                            </div>
                                            <div className='card-footer1'>
                                                <div className='card-nombre'>
                                                    <div className='card-name-nombre'>
                                                        <i class="fa-solid fa-star"></i>
                                                        <h1 style={{ marginLeft: '2px' }}>{anuncio.nombre}</h1>
                                                    </div>
                                                    <span>{anuncio.edad} <small>años</small></span>
                                                </div>
                                                <div className='card-footer-nacio'>
                                                    <img src={anuncio.iconBanader} />
                                                    <span>{anuncio.nacionalidad}</span>
                                                </div>
                                                <div className='card-lugar'>
                                                    <div>
                                                        <img src='https://res.cloudinary.com/dtbxszqkz/image/upload/v1691439005/iconos/pointuno_hjti6w.png' />
                                                        <span>{anuncio.distrito}</span>
                                                    </div>
                                                    <small>{anuncio.provincia}</small>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            {/*<LoadMorePublic />*/}
                            <LoadMoreAnu directoryRef={directoryRef} />
                            <Footer />
                        </div>
                    </div>
                </div>
                <div className='rj-derecha'>
                    <BarraDer />
                </div>
            </div>
        </>

    )
}

export default Inicio
