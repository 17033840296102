import React, { useState, useContext } from 'react'
import Posts from '../home/Posts'
import { useSelector, useDispatch } from 'react-redux'
import LoadIcon from '../../images/loading.gif'
import { GlobalState } from '../../GlobalState'
import { useParams } from 'react-router-dom'

function ComponetsHome() {
    //tipo de usuario
    const state = useContext(GlobalState)
    const [isUservip] = state.userAPI.isUservip
    //
    const { homePosts } = useSelector(state => state)
    const { profile, auth } = useSelector(state => state)
    const dispatch = useDispatch()

    const { id } = useParams()

    return (
        <div >
            {
                homePosts.loading
                    ? <img src={LoadIcon} alt="loading" className="d-block mx-auto" />
                    : (homePosts.result === 0 && homePosts.posts.length === 0)
                        ? <div className='notpost'> <h2 className="text-center">No Post</h2> </div> 
                        : <Posts />
            }
        </div>
    )
}

export default ComponetsHome