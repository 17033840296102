import { TIKTOK_TYPES } from '../actions/tiktokAction'

const initialLinck = {
    loading: false,
    tiktoks: [],
    result: 0,
    page: 2
}

const linktiktokReducer = (state = initialLinck, action) => {
    switch (action.type) {
        case TIKTOK_TYPES.CREATE_TIKTOK:
            return {
                ...state,
                tiktoks: [...state.tiktoks, action.payload]
            };
        case TIKTOK_TYPES.LOADING_TIKTOK:
            return {
                ...state,
                loading: action.payload
            };
        case TIKTOK_TYPES.GET_TIKTOK:
            return {
                ...state,
                tiktoks : action.payload.tiktoks,
                result : action.payload.result
            };
        default:
            return state;
    }
}

export default linktiktokReducer